<template>
    <section :class="CSSClasses" :style="`background-image: url(${heroImageUrl})`">
        <div class="layout">
            <div class="left">
                <div v-if="eyebrow" class="eyebrow">
                    <TypeLabel v-if="eyebrow && eyebrowTag" :name="eyebrow" />
                    <a v-if="eyebrow && !eyebrowTag && eyebrow === 'Programs'" href="/programs-and-projects?lang=en">
                        <Eyebrow v-if="eyebrow && !eyebrowTag" :hasMargin="eyebrowMargin">{{ eyebrow }}</Eyebrow>
                    </a>
                    <Eyebrow v-else-if="eyebrow && !eyebrowTag" :hasMargin="eyebrowMargin">{{
                        $t(eyebrow, 1, {
                            locale: pageLanguage,
                        })
                    }}</Eyebrow>
                    <div class="image-mobile">
                        <slot name="image">
                            <Image v-if="imageUrl" :url="imageUrl" class="image-mobile" />
                        </slot>
                    </div>
                </div>

                <div class="header">
                    <div class="name">
                        <Typography as="div" :variant="titleVariant" class="heading">{{ heading }}</Typography>

                        <div class="image-tablet">
                            <slot name="image">
                                <Image v-if="imageUrl" :url="imageUrl" />
                            </slot>
                        </div>
                    </div>

                    <Typography as="div" variant="body-display-large-italic" class="dek">{{ dek }}</Typography>

                    <RichTextElements :elements="richText" class="dek" bodyStyle="serif-large" />

                    <slot name="headerDetails"></slot>
                </div>
            </div>
            <Image v-if="logo" :url="logo" class="logo right" />
            <slot name="rightContent"></slot>
        </div>
        <img v-if="showTopographic" src="~/assets/svg/topographic.svg?url" class="topographic-background" />
    </section>
</template>

<script setup>
const props = defineProps({
    eyebrow: {
        type: String,
        default: '',
    },
    heading: {
        type: String,
        default: '',
    },
    dek: {
        type: String,
        default: '',
    },
    imageUrl: {
        type: String,
        default: '',
    },
    heroImageUrl: {
        type: String,
        default: '',
    },
    accentColor: {
        type: String,
        default: '',
    },
    logo: {
        type: String,
        default: '',
    },
    eyebrowMargin: {
        type: Boolean,
        default: false,
    },
    richText: {
        type: Object,
    },
    showTopographic: {
        type: Boolean,
        default: false,
    },
    isTall: {
        type: Boolean,
        default: false,
    },
    isSansSerif: {
        type: Boolean,
        default: false,
    },
    eyebrowTag: {
        type: Boolean,
        default: false,
    },
    hasHeaderOffset: {
        type: Boolean,
        default: false,
    },
    class: {
        type: String,
        default: '',
    },
    pageLanguage: {
        type: String,
        default: '',
    },
});

const CSSClasses = computed(() => [
    'hero-basic',
    props.accentColor && `background-${props.accentColor}`,
    props.logo && 'has-logo',
    props.isTall && 'is-tall',
    props.hasHeaderOffset && 'header-offset',
    props.heroImageUrl && 'hero-image-gradient',
    props.class,
]);

const titleVariant = computed(() => {
    return props.isSansSerif ? 'h1' : 'h1-display';
});
</script>

<style lang="scss" scoped>
.hero-basic {
    position: relative;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;

    &.background-grey {
        background-color: color(grey, light);

        .eyebrow {
            color: color(noir, default, 0.3);
        }

        .heading {
            color: color(noir, default);
        }

        .eyebrow {
            color: color(offnoir, default, 0.3);
        }

        .dek {
            color: color(noir, default, 0.7);
        }
    }

    &.background-blanc {
        background-color: color(blanc);

        .heading {
            color: color(noir);
        }

        .eyebrow,
        .dek {
            color: color(offnoir);
        }
    }

    &.background-blue {
        background-color: color(blue, dark);

        .dek {
            color: color(blanc, default, 0.6);
        }
    }

    &.topic-region {
        overflow: visible;
        @include media-query(phone) {
            @include z-index(header, -1);
        }
    }
}

.topographic-background {
    position: absolute;
    top: 0;
    inset-inline-end: -20%;
    mix-blend-mode: multiply;
    opacity: 0.75;

    @include media-query(phone) {
        inset-inline-end: -50%;
        opacity: 0.65;
    }
}

.hero-image-gradient {
    &:before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, color(noir, default, 0.5), transparent);
        @include z-index(below-arbitrary);
    }
}

.layout {
    @include content-section;
    padding-top: vertical-space(4);
    padding-bottom: vertical-space(2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @include z-index(arbitrary);

    .is-tall & {
        min-height: 30rem;
        align-items: stretch;

        .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .header-offset & {
        padding-top: calc(vertical-space(4) + var(--header-height));
    }
}

.left,
.right {
    flex: 1;
    @include content-area-padding;
}

.eyebrow {
    color: color(blanc, default, 0.6);
    margin-bottom: vertical-space(1);
    display: flex;
    justify-content: space-between;

    @include media-query(tablet) {
        margin-bottom: 0;
    }

    @include media-query(phone) {
        margin-bottom: 5.2rem;
    }
}

.header {
    width: 100%;
}

.heading {
    color: color(blanc);
    margin-bottom: 2rem;
}

.image-tablet,
.image-mobile {
    @include z-index(above-arbitrary);
    @include media-query(full) {
        display: none;
    }
}

.image-tablet {
    @include content-section-padding;
    @include media-query(phone) {
        display: none;
    }
}

.image-mobile {
    @include media-query(not-phone) {
        display: none;
    }
}

.dek {
    color: color(noir, default, 0.6);

    max-width: 72rem;

    @include media-query(phone) {
        font-size: 1.5rem;
        line-height: 1.133333333333333;
        letter-spacing: -0.02em;
    }
}

.name {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.logo {
    height: 100%;
    max-width: 30rem;
    @include z-index(-999999999);

    @include media-query(mobile) {
        display: none;
    }
}

.mobile-hero {
    display: none;
    @include media-query(phone) {
        display: block;
    }
}

.desktop-hero {
    display: block;
    @include media-query(phone) {
        .mobile-hero ~ & {
            display: none;
        }
    }
}
</style>
